<template>
  <div class="help-contact">
    <PageHeader is-dark>
      Help/Contact
    </PageHeader>
    <section class="help-contact__content">
      <img
        src="/img/help-bubble.png"
        width="277"
        height="105"
        alt=""
      >
      <v-form class="help-contact__form">
        <v-textarea
          v-model="message"
          hide-details="auto"
          rounded
          outlined
          dark
          no-resize
          required
          color="var(--contrast-text-color)"
        />
        <AppButton
          width="172"
          height="37"
          dark
          color="var(--button-primary-color)"
          class="help-contact__form-submit"
          @click="goToSendEmail"
        >
          Submit
        </AppButton>
      </v-form>
    </section>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  name: 'HelpContact',
  components: {
    PageHeader
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
    goToSendEmail() {
      window.open(
          `mailto:gary@unghosted.co?subject=Unghosted help/contact&body=${this.message}`,
          '_blank'
      )
      this.message = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.help-contact {
  @include default-page-paddings;

  background: rgba(19, 33, 63, 0.89);

  &__content {
    margin: 80px auto 0;
    max-width: 840px;

    @include breakpoint-reverse(small) {
      margin-top: 40px;
    }
  }

  &__form {
    margin-top: 20px;
    max-width: 840px;
    text-align: center;

    &-submit {
      margin-top: 55px;

      &::v-deep .text {
        font-size: var(--font-size-sub);
      }
    }
  }

  @include breakpoint-reverse(medium) {
    padding: 37px 24px;
  }

  @include breakpoint-reverse(small) {
    margin-left: 0;
    padding: 12px;
  }
}
</style>
